import Vue from "vue";
import VueRouter from "vue-router";

// import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "none",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginView.vue"),
  },
  {
    path: "/casa",
    name: "casa",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
  },
  {
    path: "/cuentame",
    name: "cuentame",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GosipView.vue"),
  },
  {
    path: "/memorias",
    name: "memorias",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "cards" */ "../views/CardsView.vue"),
  },
  {
    path: "/videos",
    name: "videos",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "cards" */ "../views/VideosView.vue"),
  },
  {
    path: "/login",
    name: "LoginView",
    component: () =>
      import(/* webpackChunkName: "Post" */ "../views/LoginView.vue"),
  },
  {
    path: "/ayuda",
    name: "Ayud",
    component: () =>
      import(/* webpackChunkName: "Post" */ "../views/HelpView.vue"),
  },
];

const router = new VueRouter({
  routes,
});
// const router = createRouter({
//   history: createWebHistory(),
//   routes,
// });

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.isUserLoggedIn) {
      // alert("Necesitas autenticación para ver esta página");
      router.push({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
