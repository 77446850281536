<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="light" variant="secondary">
        <b-container>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item
                v-if="!$store.state.isUserLoggedIn"
                :to="{ name: 'login' }"
              ></b-nav-item>
            </b-navbar-nav>
            <b-navbar-nav
              v-if="$store.state.isUserLoggedIn"
              style="font-family: 'Durer'; font-weight: bold"
            >
              <b-nav-item :to="{ name: 'casa' }">Inicio</b-nav-item>
              <b-nav-item :to="{ name: 'cuentame' }">Cuéntame</b-nav-item>
              <b-nav-item :to="{ name: 'memorias' }">Memorias</b-nav-item>
              <b-nav-item :to="{ name: 'videos' }">Videos</b-nav-item>
            </b-navbar-nav>

            <b-navbar-nav class="ml-auto" v-if="$store.state.isUserLoggedIn">
              <b-button class="closeButton" @click="logout"
                ><b-icon icon="power" aria-hidden="true"></b-icon>
                Cerrar</b-button
              >
              <b-button @click="getHelp" class="infoButton">
                <b-icon
                  class="infoIcon"
                  icon="info-circle"
                  variant="dark"
                ></b-icon>
                Ayuda
              </b-button>
            </b-navbar-nav>
          </b-collapse>
        </b-container>
      </b-navbar>
    </div>
    <router-view />
    <div v-if="this.$store.state.isUserLoggedIn"><BVFooter /></div>
    <div v-if="!this.$store.state.isUserLoggedIn">
      <BVFooter style="position: fixed" />
    </div>
  </div>
</template>

<script>
import BVFooter from "@/components/BVFooter.vue";

export default {
  components: {
    BVFooter,
  },
  async beforeMount() {
    document.title = "LosPerezEnElRio";
  },
  methods: {
    getHelp() {
      this.$router.push({ path: "/ayuda" });
    },
    logout() {
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      //redirect
      this.$router.push({ path: "/login" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_custom.scss";

@font-face {
  font-family: "Durer";
  src: local("Durer"), url(./fonts/Durer.ttf) format("truetype");
}
#app {
  font-family: Avenir, Helvetica;
  font-size: xx-large;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@media screen and (max-width: 768px) {
  #app {
    margin: 0;
  }
}
</style>
