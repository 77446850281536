<template>
  <footer class="footer">
    <b-container>
      <b-row>
        <b-col style="text-align: left" cols="12" md="8" lg="8">
          <a href="#" @click="goTo"><i></i>Inicio</a><br />
          <p>Contacto: <i>losperezenelrio@gmail.com</i></p>
        </b-col>
        <b-col style="text-align: left" cols="12" md="4" lg="4">
          <!-- eslint-disable-next-line prettier/prettier -->
          © 2022 Los Perez en el Rio All Right Reversed<br />Website built by
          Aurora Del Rio
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
export default {
  name: "BVFooter",

  data() {},
  methods: {
    goTo() {
      this.$router.push({ path: "/casa" });
    },
  },
};
</script>

<style scoped>
.footer {
  margin-top: 10%;
  background-color: rgb(244, 223, 203);

  /* border-top: 2px double rgb(140, 138, 138); */
  /* border-radius: 25px; */
  padding: 25px;
  /* position: fixed; */
  left: 0;
  bottom: 0;
  width: 100%;
}
* {
  font-size: small;
}
</style>
